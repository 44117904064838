exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-1-js": () => import("./../../../src/pages/blog/post1.js" /* webpackChunkName: "component---src-pages-blog-post-1-js" */),
  "component---src-pages-blog-post-2-js": () => import("./../../../src/pages/blog/post2.js" /* webpackChunkName: "component---src-pages-blog-post-2-js" */),
  "component---src-pages-blog-post-3-js": () => import("./../../../src/pages/blog/post3.js" /* webpackChunkName: "component---src-pages-blog-post-3-js" */),
  "component---src-pages-blog-post-4-js": () => import("./../../../src/pages/blog/post4.js" /* webpackChunkName: "component---src-pages-blog-post-4-js" */),
  "component---src-pages-blog-post-5-js": () => import("./../../../src/pages/blog/post5.js" /* webpackChunkName: "component---src-pages-blog-post-5-js" */),
  "component---src-pages-blog-post-6-js": () => import("./../../../src/pages/blog/post6.js" /* webpackChunkName: "component---src-pages-blog-post-6-js" */),
  "component---src-pages-blog-post-7-js": () => import("./../../../src/pages/blog/post7.js" /* webpackChunkName: "component---src-pages-blog-post-7-js" */),
  "component---src-pages-blog-post-8-js": () => import("./../../../src/pages/blog/post8.js" /* webpackChunkName: "component---src-pages-blog-post-8-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-neural-operators-pde-control-js": () => import("./../../../src/pages/neuralOperatorsPDEControl.js" /* webpackChunkName: "component---src-pages-neural-operators-pde-control-js" */),
  "component---src-pages-operators-link-js": () => import("./../../../src/pages/operatorsLink.js" /* webpackChunkName: "component---src-pages-operators-link-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

